var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('h3',[_vm._v("Web Settings")]),_c('b-form-group',{attrs:{"label":"Maintenance Mode","label-cols":"2"}},[_c('toggle-button',{staticClass:"large-font",attrs:{"sync":true,"width":100,"height":40,"labels":{
        checked   : 'ON',
        unchecked : 'OFF'
      },"color":{
        checked   : '#75c791',
        unchecked : '#ff877b',
      }},on:{"change":function($event){return _vm.updateMaintenance($event)}},model:{value:(_vm.maintenance),callback:function ($$v) {_vm.maintenance=$$v},expression:"maintenance"}})],1),_c('v-dialog'),_c('AppLoaderAdmin',{attrs:{"isLoading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }