<template>
  <div class="container-fluid">
    <h3>Web Settings</h3>
    <b-form-group
      label="Maintenance Mode"
      label-cols="2">
      <toggle-button
        class="large-font"
        v-model="maintenance"
        :sync="true"
        @change="updateMaintenance($event)"
        :width="100"
        :height="40"
        :labels="{
          checked   : 'ON',
          unchecked : 'OFF'
        }"
        :color="{
          checked   : '#75c791',
          unchecked : '#ff877b',
        }"
      />
    </b-form-group>

    <!-- Dialog -->
    <v-dialog />

    <!-- Loading -->
    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading     : false,
        maintenance : this.$store.getters['web/isMaintenance'] === 'true' ?
          true : false,
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    methods : {

      /**
       * Update Maintenance
       */
      updateMaintenance(evt) {
        const action = evt.value === true ? 'ON' : 'OFF';
        const newVal = evt.value ? 'true' : 'false';
        this.$modal.show('dialog', {
          title : 'Website Settings',
          text  : 'Are you sure you want to switch ' + action +
            ' the maintenance mode?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.loading = true;
              this.$http.put('api/web/settings', {
                name  : 'maintenance',
                value : newVal,
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success',
                  text  : 'Updated website settings successfully',
                });
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              }).finally(() => {
                this.loading = false;
              });
            },
          }, {
            title   : 'No',
            handler : () => {
              this.maintenance = !evt.value;
              this.$modal.hide('dialog');
            },
          } ],
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .form-group {
    .large-font {
      margin-top: 0.25rem;
    }
  }
</style>